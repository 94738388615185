import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  isAfter, isSameDay, parseISO
} from 'date-fns';

import { ALL_EMPLOYEES_TAB, CUSTOM_TAB } from '../../constants/common';
import { STATUS_ACTIVE } from '../../constants/status';
import { VACATION_FILTER_OTHER_OPTIONS } from '../../constants/vacations';
import { MAX_FETCH_DATA_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { sortBy } from '../../utils/arrayHelpers';
import {
  createDateWithoutTimezoneOffset,
  formatStringDate,
  getEndOfMonth,
  getFirstDay,
  getLastDay,
  getStartOfMonth,
  parseHolidaysData,
} from '../../utils/helpers';
import {
  generationRows,
  generationWorklogs,
  getDays,
  getPeriodLists,
  setSubmitType,
} from '../../utils/vacation';
import { searchUsers } from '../search/slice';

const today = new Date();
const formatToday = formatStringDate(today);

export const initialState = {
  isLoading: true,
  isLoadingHistory: true,
  isVacationRequestsLoading: true,
  isFollowVacationLoading: { userId: '', isLoading: false },
  isCalendarFilterLoading: false,
  isCalendarDetailedLoading: false,
  history: [],
  info: {},
  isLoadingInfo: true,
  followingUsers: [],
  suggestedUsers: [],
  users: { data: [], meta: {} },
  usersIdsAll: [],
  datepickerHistory: [],
  numberModalManagersToShow: 0,
  selectedDepartments: [],
  selectedCalendarUsers: [],
  departmentsUsers: [],
  departments: undefined,
  showMoreDepartments: false,
  rows: [],
  isCalendarTimelineLoading: true,
  days: getDays(getFirstDay(formatToday), getLastDay(formatToday), []),
  currentCalendarView: 'calendar',
  approvers: undefined,
  isRequestSubmit: [],
  vacationRequests: undefined,
  searchUsers: undefined,
  isSearchLoading: false,
  showSnackbar: false,
  currentCalendarRequest: {},
  showTimelineTooltip: false,
  numberOwnManagersToShow: 0,
  showOnlyVacation: false,
  numberManagersToShow: 0,
  initialWorklogs: [],
  errors: {},
  calendarUserRequests: {
    upcomingList: [],
    rightNowList: [],
  },
  isLoadingCalendarUserRequests: true,
  calendarTableRequests: {
    upcomingList: [],
    rightNowList: [],
  },
  calendarTablePeriod: 'rightNow',
  isLoadingCalendarTable: true,
  calendar: {
    from: getFirstDay(formatToday),
    to: getLastDay(formatToday),
    isLoading: true,
  },
  requestModalProps: {
    isModalOpen: false,
    modalId: 'requestVacation',
    error: {},
    pending: false,
  },
  datepicker: {
    from: today,
    to: today,
  },
  sentModalProps: {
    isModalOpen: false,
    modalId: 'sentModal',
    startDate: today,
    endDate: today,
    workdays: 0,
  },
  deleteModalProps: {
    isModalOpen: false,
    modalId: 'deleteVacation',
    isLoading: false,
    currentRequest: {},
  },
  moreProps: {
    isOpen: false,
    id: '',
    style: {
      transform: 'translateY(-50%)',
    },
  },
  followingInputValue: '',
  calendarOptions: {
    isOpen: false,
    title: '',
    dataList: [],
    searchValue: '',
    selectedIds: [],
    activeTab: CUSTOM_TAB,
    isVacationToday: false,
    isWithVacation: false,
    isRememberChecked: false,
    isChangeOption: false,
    otherOptions: [],
    departmentIds: [],
    projectIds: [],
    userIds: [],
    isAllEmployeesVacationToday: false,
    allEmployeesIds: [],
  },
  calendarModalDetails: {
    isModalOpen: false,
    modalId: 'calendarModalDetails',
    user: {},
    nextPeriodData: {},
    approvers: [],
  },
};

export const setSelectedDepartments = createAction(
  'vacations/setSelectedDepartments',
  (departments) => ({ payload: departments })
);

export const takeVacation = createAsyncThunk(
  'vacations/take',
  async (arg, { rejectWithValue, getState }) => {
    const { datepicker } = getState().vacation;
    const startDate = datepicker?.from;
    const endDate = datepicker?.to || datepicker?.from;

    try {
      const { data, status } = await axios
        .post(
          '/vacations',
          {
            start_date: startDate ? formatStringDate(startDate, 'y-MM-dd') : undefined,
            end_date: endDate ? formatStringDate(endDate, 'y-MM-dd') : undefined,
            ...(arg.length > 0 ? { description: arg } : {})
          }
        );

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestVacationAction = createAsyncThunk(
  'vacations/action',
  async (arg, { rejectWithValue }) => {
    const { id, confirm, isForce } = arg;

    try {
      const { data, status } = await axios
        .post(
          `/vacations/${id}/${isForce ? 'force_confirm' : 'confirm'}`,
          { confirm }
        );

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOwnVacationRequests = createAsyncThunk(
  'vacations/own',
  async (arg, { rejectWithValue }) => {
    const params = {
      page: 1,
      per_page: 500,
      with: ['confirmations.user'],
      filters: {
        end_date: { gte: formatStringDate(today, 'y-MM-dd') },
        user: { id: [arg] }
      }
    };

    try {
      const { data, status } = await axios
        .get('/vacations', { params });

      return status === 200 ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchHolidays = createAsyncThunk(
  'vacations/holidays',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios
        .get(`/holidays?filters[start_date]=${arg.from}&filters[end_date]=${arg.to}`);

      return status === 200 ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchVacationUpcomingRequests = createAsyncThunk(
  'vacations/upcoming-requests',
  async ({ id }, { rejectWithValue, signal }) => {
    const params = {
      per_page: 500,
      page: 1,
      with: ['user'],
      filters: {
        status: ['approved'],
        user: { id: [id] }
      }
    };

    try {
      const { data, status } = await axios.get('/vacations', { params, signal });

      return status === 200 ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchQueryUsers = createAsyncThunk(
  'vacations/query-users',
  async (ids, { rejectWithValue }) => {
    const params = {
      filters: {
        id: typeof ids === 'string' ? [ids] : ids,
        status: [STATUS_ACTIVE],
      },
      sort_selected: { id: ids },
    };

    try {
      const { data, status } = await axios.get('/users', { params });

      return status === 200 ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTimelineRequests = createAsyncThunk(
  'vacations/timeline-requests',
  async (arg, { rejectWithValue, getState }) => {
    const {
      calendarOptions: {
        activeTab, userIds, allEmployeesIds
      }
    } = getState().vacation;

    const currentUserIds = activeTab === ALL_EMPLOYEES_TAB ? allEmployeesIds : userIds;

    if (currentUserIds.length === 0) {
      return { data: [], userIds: [] };
    }

    const params = {
      per_page: MAX_FETCH_DATA_PER_PAGE,
      page: 1,
      with: ['user'],
      filters: {
        start_date: { lte: arg.to },
        end_date: { gte: arg.from },
        status: ['approved'],
        user: { id: currentUserIds },
      },
      sort: 'start_date',
    };

    try {
      const { data, status } = await axios.post('/vacations/list', params);

      const usersDataForCalendar = {
        data: data.data,
        userIds: currentUserIds,
      };

      return status === 200 ? usersDataForCalendar : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTableRequests = createAsyncThunk(
  'vacations/table-requests',
  async (arg, { rejectWithValue, getState }) => {
    const {
      calendarOptions: {
        activeTab, userIds, allEmployeesIds
      },
      selectedCalendarUsers
    } = getState().vacation;

    let currentUserIds = activeTab === ALL_EMPLOYEES_TAB ? allEmployeesIds : userIds;

    if (selectedCalendarUsers?.length > 0) {
      currentUserIds = [selectedCalendarUsers[0].id];
    }

    const params = {
      per_page: MAX_FETCH_DATA_PER_PAGE,
      page: 1,
      with: ['user.department'],
      filters: {
        end_date: { gte: formatStringDate(today, 'Y-MM-dd') },
        status: ['approved'],
        user: { id: currentUserIds },
      },
    };

    try {
      const { data, status } = await axios.post('/vacations/list', params);

      const getData = () => (currentUserIds.length > 0 ? data.data : []);

      return status === 200 ? getData() : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchVacationHistory = createAsyncThunk(
  'vacations/history',
  async (arg, { rejectWithValue, getState }) => {
    const { datepicker } = getState().vacation;
    const tempDate = new Date(arg && arg.date ? arg.date : datepicker.from);
    const startDate = getStartOfMonth(new Date(tempDate));
    tempDate.setMonth(tempDate.getMonth() + 1);
    const endDate = getEndOfMonth(tempDate);
    const params = {
      page: 1,
      per_page: 500,
      with: ['confirmations.user'],
      filters: {
        start_date: { lte: formatStringDate(new Date(endDate), 'y-MM-dd') },
        end_date: { gte: formatStringDate(new Date(startDate), 'y-MM-dd') },
        user: { id: [arg.id] }
      }
    };

    try {
      const { data, status } = await axios.get('/vacations', { params });

      return status === 200 ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchApprovers = createAsyncThunk(
  'vacations/approvers',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/vacations/users/${arg}/approvers`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchVacationRequests = createAsyncThunk(
  'vacations/vacation-requests',
  async (profileId, { rejectWithValue, getState }) => {
    const { tableHeadData: { showAll } } = getState().vacationRequests;

    const query = {
      per_page: showAll ? 5 : MAX_FETCH_DATA_PER_PAGE,
      with: showAll ? ['user', 'user.manager', 'confirmations.user'] : ['user']
    };
    const filters = showAll ? {} : {
      confirmations: { user_id: [profileId] },
    };

    const params = {
      page: 1,
      ...query,
      filters: {
        ...filters,
        show_inactive: 0,
        status: ['pending']
      },
    };

    try {
      const { data, status } = await axios
        .get('/vacations', { params });

      return status === 200 ? data.data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  'vacations/info',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/vacations/users/${arg}/info`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchFollowUsers = createAsyncThunk(
  'vacations/followers',
  async (filters, { rejectWithValue }) => {
    const params = {
      per_page: MAX_FETCH_DATA_PER_PAGE,
      filters,
      sort: 'name'
    };

    try {
      const { data, status } = await axios.get('/users', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSuggestedUsers = createAsyncThunk(
  'vacations/suggested',
  async ({ departmentId, userId }, { rejectWithValue, getState }) => {
    const { followingUsers } = getState().vacation;
    try {
      const params = { sort: 'name' };
      const userParams = {
        page: 1,
        per_page: MAX_FETCH_DATA_PER_PAGE,
        sort: 'name',
        filters: {
          status: [STATUS_ACTIVE]
        }
      };

      const { data, status } = await axios.get(`/departments/${departmentId}`, { params });
      let suggestedUsers = data.users.filter(({ id }) => id !== userId).filter(({ id }) => (
        followingUsers.data?.every((item) => item.id !== id)
      ));

      if (suggestedUsers.length === 0) {
        const response = await axios.get('/users', { params: userParams });
        suggestedUsers = response.data.data.filter(({ id }) => (
          followingUsers.data?.every((item) => item.id !== id)
        ));
      }

      return status === 200 ? suggestedUsers : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const followVacation = createAsyncThunk(
  'vacations/follow',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.post(`/users/${arg.user.id}/follow`);

      return status === 200 ? arg : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const softDeleteVacation = createAsyncThunk(
  'vacations/soft-delete',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/vacations/${arg.id}`);

      return status === 204 ? arg : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const softRecoverVacation = createAsyncThunk(
  'vacations/soft-recover',
  async (arg, { rejectWithValue }) => {
    const startDate = createDateWithoutTimezoneOffset(arg.start_date);
    const endDate = createDateWithoutTimezoneOffset(arg.end_date);

    try {
      const { data, status } = await axios
        .post(
          '/vacations',
          {
            start_date: formatStringDate(startDate, 'y-MM-dd'),
            end_date: formatStringDate(endDate, 'y-MM-dd'),
            ...(arg.length > 0 ? { description: arg } : {})
          }
        );

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchTimelineData = createAsyncThunk(
  'vacations/timeline-filter',
  async (arg, { rejectWithValue, dispatch, getState }) => {
    const { calendar } = getState().vacation;

    const prevMonth = getFirstDay(new Date(calendar.to));
    const nextMonth = getLastDay(new Date(calendar.to));

    try {
      return [...(await Promise.all([
        dispatch(searchUsers({ per_page: 500, filters: { status: [STATUS_ACTIVE] } })),
        dispatch(fetchTimelineRequests({ from: prevMonth, to: nextMonth })),
        dispatch(fetchHolidays({ from: calendar.from, to: calendar.to }))
      ])), calendar];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCalendarUserDetailsById = createAsyncThunk(
  'vacations/calendarDetailed',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await Promise.all([
        axios.get(`/vacations/users/${id}/info`),
        axios.get(`/vacations/users/${id}/approvers`),
        axios.get(`/users/${id}`)
      ]);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUsersForFilter = createAsyncThunk(
  'vacations/fetchUsersForFilter',
  async (arg, { rejectWithValue, getState }) => {
    const {
      calendarOptions: {
        departmentIds, projectIds, otherOptions, activeTab, isAllEmployeesVacationToday
      }
    } = getState().vacation;

    if (!activeTab && !departmentIds.length && !projectIds.length && !otherOptions.length) {
      return [];
    }

    const body = {
      per_page: 500,
      filters: {
        status: [STATUS_ACTIVE]
      }
    };

    if (activeTab === ALL_EMPLOYEES_TAB) {
      if (isAllEmployeesVacationToday) {
        body.or_filters = {
          on_vacation: true,
        };
      }
    } else {
      body.or_filters = {
        departments: {
          id: departmentIds
        },
        projects: {
          id: projectIds
        },
        only_following: otherOptions.includes(VACATION_FILTER_OTHER_OPTIONS.MY_FOLLOWING),
        only_my_subordinates: otherOptions.includes(VACATION_FILTER_OTHER_OPTIONS.APPROVED_VACATIONS_BY_ME),
        only_my_mentors: otherOptions.includes(VACATION_FILTER_OTHER_OPTIONS.MY_MENTORS),
        // on_vacation: otherOptions.includes(VACATION_FILTER_OTHER_OPTIONS.ONLY_TODAY), TODO: implement this option
      };
    }

    try {
      const { data, status } = await axios.post('/users/search', body);

      const userTempIds = data.data.map(({ id }) => id);
      const res = { activeTab, userTempIds };

      return status === 200 ? res : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const vacationSlice = createSlice({
  name: 'vacation',
  initialState,
  reducers: {
    resetState: () => initialState,
    toggleDeleteModal: (state, action) => {
      state.deleteModalProps.isModalOpen = action.payload;
    },
    setSelectedCalendarUsers: (state, action) => {
      if (state.selectedCalendarUsers.find(({ id }) => action.payload.id === id)) {
        state.selectedCalendarUsers = state.selectedCalendarUsers.filter(({ id }) => id !== action.payload.id);
      } else {
        state.selectedCalendarUsers = [...state.selectedCalendarUsers, action.payload];
      }
    },
    setShowTimelineTooltip: (state, action) => {
      state.showTimelineTooltip = action.payload;
    },
    setCurrentCalendarPeriod: (state, { payload }) => {
      state.calendarTablePeriod = payload;
    },
    toggleMore: (state, action) => {
      state.moreProps = action.payload.style ? action.payload : initialState.moreProps;
    },
    toggleRequestModal: (state, action) => {
      state.requestModalProps = {
        ...initialState.requestModalProps,
        isModalOpen: action.payload
      };
      state.datepicker = initialState.datepicker;
    },
    setDeleteModalInfo: (state, action) => {
      state.deleteModalProps.currentRequest = action.payload;
    },
    toggleSentModal: (state, action) => {
      state.sentModalProps.isModalOpen = action.payload;
    },
    toggleShowSnackbar: (state, action) => {
      state.showSnackbar = action.payload;
    },
    setCalendarDate: (state, { payload }) => {
      state.calendar = { from: payload.from, to: payload.to };
    },
    setCurrentCalendarView: (state, action) => {
      state.currentCalendarView = action.payload;
    },
    clearSearchUsers: (state) => {
      state.searchUsers = undefined;
    },
    clearCalendarInfo: (state) => {
      state.calendarUserRequests = initialState.calendarUserRequests;
      state.isLoadingCalendarUserRequests = true;
    },
    setCurrentCalendarRequest: (state, action) => {
      state.currentCalendarRequest = action.payload;
    },
    setNumberModalManagers: (state, action) => {
      state.numberModalManagersToShow = action.payload;
    },
    setNumberOwnManagers: (state, action) => {
      state.numberOwnManagersToShow = action.payload;
    },
    setDatePickerData: (state, action) => {
      state.datepicker = action.payload;
    },
    setShowMoreDepartments: (state, action) => {
      state.showMoreDepartments = action.payload;
    },
    resetFilter: (state, action) => {
      state[action.payload] = initialState[action.payload];
    },
    setNumberManagersToShow: (state, action) => {
      state.numberManagersToShow = action.payload;
    },
    setFollowingInputValue: (state, action) => {
      state.followingInputValue = action.payload;
    },
    setCalendarOptions: (state, action) => {
      state.calendarOptions = { ...state.calendarOptions, ...action.payload };
    },
    setCalendarModalDetails: (state, action) => {
      state.calendarModalDetails = { ...state.calendarModalDetails, ...action.payload };
    },
    resetCalendarOptions: (state) => {
      state.calendarOptions = { ...initialState.calendarOptions, isOpen: true };
    },
    setCalendarFilterLoading: (state, action) => {
      state.isCalendarFilterLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedDepartments, (state, action) => {
      if (state.selectedDepartments.find((item) => action.payload === item)) {
        state.selectedDepartments = state.selectedDepartments.filter((item) => item !== action.payload);
      } else {
        state.selectedDepartments = [...state.selectedDepartments, action.payload];
      }
    });
    builder
      .addCase(fetchTableRequests.pending, (state) => {
        state.isLoadingCalendarTable = true;
      })
      .addCase(fetchTableRequests.fulfilled, (state, { payload }) => {
        state.isLoadingCalendarTable = false;
        state.calendarTableRequests = getPeriodLists(payload);
      });
    builder
      .addCase(fetchVacationUpcomingRequests.pending, (state) => {
        state.isLoadingCalendarUserRequests = true;
      })
      .addCase(fetchVacationUpcomingRequests.fulfilled, (state, { payload }) => {
        state.isLoadingCalendarUserRequests = false;
        state.calendarUserRequests = getPeriodLists(payload);
      });
    builder
      .addCase(fetchTimelineData.pending, (state) => {
        state.isCalendarTimelineLoading = true;
        state.calendar.isLoading = true;
      })
      .addCase(fetchTimelineData.fulfilled, (state, { payload }) => {
        const [
          allUsers, vacationsData, holidays, { from, to }
        ] = payload;

        const parseHolidays = parseHolidaysData(holidays.payload, new Date(from), new Date(to));

        const resultIds = vacationsData.payload.data.map(({ user: { id } }) => id);

        const isActiveUserId = (id) => {
          return allUsers.payload.data.some((item) => item.id === id);
        };

        const usersWithoutVacation = vacationsData.payload.userIds.reduce((acc, id) => {
          if (!resultIds.includes(id) && isActiveUserId(id)) {
            acc.push({
              id,
              user: allUsers.payload.data.find((item) => item.id === id)
            });
          }

          return acc;
        }, []);

        const usersWithVacation = vacationsData.payload.data.reduce((acc, item) => {
          if (resultIds.includes(item.user_id) && isActiveUserId(item?.user_id)) {
            acc.push({
              ...item,
              user: allUsers.payload.data.find((user) => user.id === item.user_id),
            });
          }

          return acc;
        }, []);

        const usersDataForCalendar = [...usersWithVacation, ...usersWithoutVacation];
        const activeUserIds = [...new Set(usersDataForCalendar.map(({ user: { id } }) => id))];

        state.initialWorklogs = generationWorklogs(from, to, parseHolidays, []);
        state.isCalendarTimelineLoading = false;
        state.calendar.isLoading = false;
        state.rows = generationRows(usersDataForCalendar, holidays.payload, from, to);
        state.calendarOptions.userIds = activeUserIds;
      })
      .addCase(fetchTimelineData.rejected, (state) => {
        state.isCalendarTimelineLoading = false;
        state.calendar.isLoading = false;
      });
    builder.addCase(fetchHolidays.fulfilled, (state, { payload, meta }) => {
      state.days = getDays(
        meta.arg.from,
        meta.arg.to,
        parseHolidaysData(payload, new Date(meta.arg.from), new Date(meta.arg.from)),
      );
    });
    builder
      .addCase(requestVacationAction.pending, (state, action) => {
        state.isRequestSubmit = [
          ...state.isRequestSubmit,
          { id: action.meta.arg.id, type: setSubmitType(action.meta.arg.isForce, action.meta.arg.confirm) }
        ];
      })
      .addCase(requestVacationAction.fulfilled, (state, action) => {
        state.isRequestSubmit = state.isRequestSubmit.filter(({ id }) => id !== action.payload.id);
        state.vacationRequests = state.vacationRequests.map((item) => (
          item.id === action.payload.id
            ? { ...action.payload, status: action.payload.status }
            : item
        ));
      })
      .addCase(requestVacationAction.rejected, (state, action) => {
        state.isRequestSubmit = state.isRequestSubmit.filter(({ id }) => id !== action.payload.id);
      });

    builder
      .addCase(softDeleteVacation.pending, (state) => {
        state.deleteModalProps.isLoading = true;
      })
      .addCase(softDeleteVacation.fulfilled, (state, action) => {
        const isNextPeriod = isAfter(parseISO(action.payload.start_date), parseISO(state.info.expiration_date))
          || isSameDay(parseISO(action.payload.start_date), parseISO(state.info.expiration_date));

        state.history = state.history.filter(({ id }) => id !== action.payload.id);
        state.showSnackbar = true;
        state.deleteModalProps.isModalOpen = false;
        state.deleteModalProps.isLoading = false;
        if (!isNextPeriod) {
          state.info.spent -= action.payload.workdays;
        }
      })
      .addCase(softDeleteVacation.rejected, (state) => {
        state.deleteModalProps.isLoading = false;
      });

    builder.addCase(softRecoverVacation.fulfilled, (state, action) => {
      const isNextPeriod = isAfter(parseISO(action.payload.start_date), parseISO(state.info.expiration_date))
        || isSameDay(parseISO(action.payload.start_date), parseISO(state.info.expiration_date));

      state.history.unshift(action.payload);
      state.showSnackbar = false;
      if (!isNextPeriod) {
        state.info.spent += action.payload.workdays;
      }
    });

    builder
      .addCase(takeVacation.rejected, (state) => {
        state.requestModalProps.pending = false;
      })
      .addCase(takeVacation.pending, (state) => {
        state.requestModalProps.pending = true;
      })
      .addCase(takeVacation.fulfilled, (state, action) => {
        if (action.payload.message) {
          state.requestModalProps.error = { message: action.payload.response.data.message };
          state.requestModalProps.pending = false;
        } else {
          const isNextPeriod = isAfter(state.datepicker.from, parseISO(state.info.expiration_date))
          || isSameDay(state.datepicker.from, parseISO(state.info.expiration_date));

          state.history.unshift(action.payload);
          state.requestModalProps.error = initialState.requestModalProps.error;
          state.requestModalProps.isModalOpen = false;
          state.requestModalProps.pending = false;
          state.sentModalProps = {
            isModalOpen: true,
            modalId: 'sentModal',
            startDate: state.datepicker.from,
            endDate: state.datepicker.to || state.datepicker.from,
            workdays: action.payload.workdays,
          };
          if (!isNextPeriod) {
            state.info.spent += action.payload.workdays;
          }
        }
      });
    builder.addCase(fetchApprovers.fulfilled, (state, action) => {
      state.approvers = action.payload;
    });
    builder.addCase(fetchSuggestedUsers.fulfilled, (state, action) => {
      state.suggestedUsers = sortBy(action.payload, 'name');
    });
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.isLoadingInfo = true;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.isLoadingInfo = false;
        state.info = action.payload;
      })
      .addCase(fetchUserInfo.rejected, (state) => {
        state.isLoadingInfo = false;
      });
    builder.addCase(fetchVacationHistory.fulfilled, (state, action) => {
      state.datepickerHistory = action.payload;
    });
    builder
      .addCase(fetchOwnVacationRequests.pending, (state) => {
        state.isLoadingHistory = true;
      })
      .addCase(fetchOwnVacationRequests.fulfilled, (state, action) => {
        state.isLoadingHistory = false;
        state.history = action.payload;
      })
      .addCase(fetchOwnVacationRequests.rejected, (state) => {
        state.isLoadingHistory = false;
      });
    builder
      .addCase(fetchFollowUsers.pending, (state) => {
        state.isSearchLoading = true;
      })
      .addCase(fetchFollowUsers.fulfilled, (state, action) => {
        if (action.meta.arg.only_following) {
          state.followingUsers = action.payload;
          state.calendarOptions.selectedIds = action.payload.data.map(({ id }) => id);

          if (state.calendarOptions.userIds.length === 0 || state.calendarOptions.isOpen) {
            state.calendarOptions.userIds = action.payload.data.map(({ id }) => id);
            state.calendarOptions.otherOptions = ['myFollowing'];
            state.calendarOptions.departmentIds = [];
            state.calendarOptions.projectIds = [];
          }
        } else {
          state.searchUsers = action.payload;
        }
        state.isSearchLoading = false;
      })
      .addCase(fetchFollowUsers.rejected, (state) => {
        state.isSearchLoading = false;
      });
    builder
      .addCase(fetchVacationRequests.pending, (state) => {
        state.isVacationRequestsLoading = true;
      })
      .addCase(fetchVacationRequests.fulfilled, (state, action) => {
        state.isVacationRequestsLoading = false;
        state.vacationRequests = action.payload.filter(({ status }) => status === 'pending');
      })
      .addCase(fetchVacationRequests.rejected, (state) => {
        state.isVacationRequestsLoading = false;
      });
    builder
      .addCase(fetchQueryUsers.fulfilled, (state, action) => {
        state.selectedCalendarUsers = action.payload;
      });
    builder
      .addCase(followVacation.pending, (state, action) => {
        state.isFollowVacationLoading.isLoading = true;
        state.isFollowVacationLoading.userId = action.meta.arg.user.id;
      })
      .addCase(followVacation.fulfilled, (state, action) => {
        state.isFollowVacationLoading.isLoading = false;
        state.isFollowVacationLoading.userId = null;
        if (action.payload.isSuggested) {
          state.followingUsers.data.push({ ...action.payload.user, follow: true });
          state.followingUsers.meta.total = state.followingUsers.data.length;
          state.suggestedUsers = sortBy(state.suggestedUsers.filter(({ id }) => id !== action.payload.user.id), 'name');
          state.calendarOptions.userIds = [...state.calendarOptions.userIds, action.payload.user.id];
        }
        if (action.payload.isFollowing) {
          state.followingUsers.data = state.followingUsers.data.filter(({ id }) => id !== action.payload.user.id);
          state.suggestedUsers = sortBy([
            ...state.suggestedUsers.filter(({ id }) => id !== action.payload.user.id),
            action.payload.user
          ], 'name');
          state.followingUsers.meta.total = state.followingUsers.data.length;
          if (state.searchUsers?.data) {
            state.searchUsers.data = state.searchUsers.data.map((item) => (
              item.id === action.payload.user.id ? { ...item, follow: false } : item
            ));
          }
          state.calendarOptions.userIds = state.calendarOptions.userIds.filter((id) => id !== action.payload.user.id);
        }
        if (!action.payload.isFollowing && !action.payload.isSuggested) {
          state.followingUsers.data.push({ ...action.payload.user, follow: true });
          state.followingUsers.meta.total = state.followingUsers.data.length;
          state.suggestedUsers = sortBy(state.suggestedUsers.filter(({ id }) => id !== action.payload.user.id), 'name');
          state.searchUsers.data = state.searchUsers?.data.map((item) => (
            item.id === action.payload.user.id ? { ...item, follow: true } : item
          ));
          state.calendarOptions.userIds = [...state.calendarOptions.userIds, action.payload.user.id];
        }
      })
      .addCase(followVacation.rejected, (state) => {
        state.isFollowVacationLoading = false;
      });
    builder
      .addCase(fetchCalendarUserDetailsById.pending, (state) => {
        state.isCalendarDetailedLoading = true;
      })
      .addCase(fetchCalendarUserDetailsById.fulfilled, (state, action) => {
        const [nextPeriodData, approvers, user] = action.payload;
        state.calendarModalDetails.nextPeriodData = nextPeriodData.data;
        state.calendarModalDetails.approvers = approvers.data;
        state.calendarModalDetails.user = user.data;
        state.isCalendarDetailedLoading = false;
      })
      .addCase(fetchCalendarUserDetailsById.rejected, (state) => {
        state.isCalendarDetailedLoading = false;
      });
    builder
      .addCase(fetchUsersForFilter.pending, (state) => {
        state.isCalendarFilterLoading = true;
      })
      .addCase(fetchUsersForFilter.fulfilled, (state, action) => {
        if (action.payload.activeTab === ALL_EMPLOYEES_TAB) {
          state.calendarOptions.allEmployeesIds = [...action.payload.userTempIds];
        } else {
          const res = action.payload?.userTempIds || [];

          state.calendarOptions.selectedIds = [...res];
          state.calendarOptions.userIds = [...res];
        }
        state.isCalendarFilterLoading = false;
      })
      .addCase(fetchUsersForFilter.rejected, (state) => {
        state.isCalendarFilterLoading = false;
      });
  }
});

export const {
  toggleDeleteModal,
  toggleShowSnackbar,
  clearSearchUsers,
  setSelectedCalendarUsers,
  setDatePickerData,
  toggleRequestModal,
  setCurrentCalendarPeriod,
  setCalendarDate,
  setCurrentCalendarView,
  setCurrentCalendarRequest,
  setNumberOwnManagers,
  setNumberModalManagers,
  setShowTimelineTooltip,
  toggleSentModal,
  setDeleteModalInfo,
  clearCalendarInfo,
  setShowMoreDepartments,
  resetFilter,
  resetState,
  setNumberManagersToShow,
  setFollowingInputValue,
  setCalendarOptions,
  setCalendarModalDetails,
  resetCalendarOptions,
  setCalendarFilterLoading
} = vacationSlice.actions;

export default vacationSlice.reducer;
